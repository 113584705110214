export const en = {
  improve_your_business: 'IMPROVE YOUR BUSINESS WITH US',
  free_presentation: 'FREE LIVE PRESENTATION',
  copied: 'Copied',
  successful_data_send: 'Your email was successfully sent',
  error_data_send: 'Cannot send your email',

  tabs: {
    about: 'About Us',
    client: 'For Clients',
    hotel: 'For Hotels',
    contacts: 'Contacts',
  },

  buttons: {
    free_month: 'Free Month',
    our_services: 'Our Services',
    send: 'Send',
    coworking_details: 'Coworking details'
  },

  home: {
    title_1: 'Smart',
    title_2: 'Hotel',
    title_3: 'Service',
    subtitle: 'An innovative system designed to optimize hotel operations and increase profitability while ensuring guests enjoy exceptional comfort and convenience during their stay.',
    about_title: 'ABOUT US',
    about_text: 'Introducing Smart Hotel Service, a progressive application created to simplify and enhance the hotel experience for guests while increasing hotel profits.<br/>With Smart Hotel Service, guests can request various hotel services, and you can easily take your business to the next level.<br/>Implementing our system allows your hotel not only to elevate the level of service but also to become a leader in hospitality by providing an exceptional experience for every guest.',
    form_title: 'INDIVIDUAL ONLINE PRESENTATION JUST FOR YOU',
    form_hint: 'Fill in and send your details, and we will call you back to arrange a FREE presentation!',
    form_name: 'First and Last Name',
    form_phone: 'Phone Number',
    benefits_title: 'ALL-IN-ONE SYSTEM',
    benefits_subtitle: 'In today’s world, hospitality is one of the key factors that determine the success of a hotel. To ensure a high level of service and customer satisfaction, we offer an innovative management system that improves all aspects of hotel operations.',
    exp_title: 'PERSONALIZED GUEST EXPERIENCE',
    exp_text: 'Our system provides guests with a personalized application that significantly simplifies their interaction with the hotel. Through the app, guests can:',
    exp_item_1: 'Order food directly to their room, choosing from a diverse restaurant menu.',
    exp_item_2: 'Request housekeeping services, selecting a convenient time for service.',
    exp_item_3: 'Order taxis and other transportation services directly through the app.',
    exp_item_4: 'Communicate with the reception for quick resolution of any issues or requests.',
    exp_item_5: 'Book spa treatments and other additional hotel services.',
    exp_button: 'All Guest Benefits',
    tool_title: 'EFFICIENT TOOL FOR STAFF',
    tool_text: 'Each hotel employee has access to a personalized workspace that optimizes the performance of their tasks:',
    tool_item_1: 'Receive real-time guest requests with the ability to respond immediately.',
    tool_item_2: 'Monitor the execution of requests with tracking of time and service quality.',
    tool_item_3: 'Reservation and booking schedules to ensure smooth and coordinated service.',
    tool_item_4: 'Analyze staff performance based on guest feedback and request completion time.',
    tool_item_5: 'Provide feedback to guests based on their reviews, helping to build trust and increase customer satisfaction.',
    tool_button: 'All Hotel Benefits',
    offer_title: 'FIRST MONTH FREE',
    offer_text: 'We are happy to answer all your additional questions and support you at every stage of system implementation, helping your business reach a new level of success.',
    offer_button: 'I Want to Improve My Business!',
    reviews_title: 'REVIEWS',
    review_1_name: 'Sarah, 48 years old',
    review_1_type: 'Hotel Manager',
    review_1_text: 'At first, I doubted the feasibility of implementing this system, but the results exceeded all expectations. The program significantly simplified our processes and increased work efficiency. This is indeed a good investment for our hotel!',
    review_2_name: 'John, 44 years old',
    review_2_type: 'Hotel Owner',
    review_2_text: 'With the implementation of this system, every guest is satisfied with our service. We receive many compliments for staying up-to-date, and we have noticed a significant increase in profits. This system has truly improved our processes and raised the overall level of service.',
    review_3_name: 'Emily, 32 years old',
    review_3_type: 'Hotel Guest',
    review_3_text: 'I was pleasantly surprised when, after scanning the code, I got access to the hotel app. Everything was incredibly convenient: I was able to order food, arrange cleaning, and use other services without leaving my room. This is really cool service!',
    review_4_name: 'Jessica, 29 years old',
    review_4_type: 'Hotel Administrator',
    review_4_text: 'At first, I was skeptical, but it turned out to be extremely useful. The system significantly eased our work by automating many routine tasks and providing convenient access to information. This is a real breakthrough for our hotel!',
    review_5_name: 'Michael, 52 years old',
    review_5_type: 'Hotel Guest',
    review_5_text: 'I was able to extend my stay without leaving the room and was pleasantly surprised at how simple and convenient it was to do it through the app. Even though I am not very tech-savvy, this system proved to be very intuitive and easy to use.',
    points_title: 'WHAT YOU GET BY UPDATING YOUR BUSINESS?',
    points_text: 'Implementing our hotel management system brings numerous benefits that contribute to improving the quality of service and efficiency of your business. Here are the main advantages of our system:',
    points_item_1_title: 'Modern Technologies:',
    points_item_1_text: 'Implementing modern technologies optimizes operations and increases the overall efficiency of the hotel.',
    points_item_2_title: 'Process Automation:',
    points_item_2_text: 'Reducing staff workload by automating administrative duties.',
    points_item_3_title: 'Personalized Service:',
    points_item_3_text: 'Thanks to the individual app, guests have convenient access to all hotel services.',
    points_item_4_title: 'Positive Reviews:',
    points_item_4_text: 'A high level of service leads to an increase in positive reviews and guest loyalty.',
    points_item_5_title: 'Proper Focus:',
    points_item_5_text: 'Staff can focus on performing more important tasks, as repetitive administrative duties are automated.',
    points_item_6_title: 'Hotel Quality Control:',
    points_item_6_text: 'Administrators can monitor request fulfillment and control the quality of service.',
    points_item_7_title: 'Advertising and Sales:',
    points_item_7_text: 'The ability to advertise and sell your services and partner services through the app allows the hotel to attract more customers and increase sales volumes.',
    points_item_8_title: 'Profit Growth:',
    points_item_8_text: 'Our system increases hotel revenues by attracting more customers and increasing sales volumes. This directly contributes to the overall profitability growth of the hotel.',
    points_item_9_title: '24/7 Support:',
    points_item_9_text: 'The app provides round-the-clock online support for both guests and hotel staff, allowing for quick resolution of any issues.',
    points_item_10_title: 'Staff Training:',
    points_item_10_text: 'We offer the possibility to train your staff so that they can effectively use all system features.',
    points_item_11_title: 'Free Trial Month:',
    points_item_11_text: 'A free month of system use, allowing the hotel to test and make necessary configurations for optimal use.',
    points_item_12_title: 'Flexibility and Variety of Features:',
    points_item_12_text: 'A "constructor" system - you choose the set of features that best suits the needs of your business.',
  },

  hotel: {
    main_title: 'NEW LEVEL OF BUSINESS',
    main_text: 'Implementing our system will significantly increase the efficiency of your hotel by automating routine processes and simplifying management. Guest convenience, quick request processing, and the integration of modern technologies will elevate service levels, positively impacting the hotel’s reputation and profitability.',
    features_title: 'EFFICIENT TOOL FOR STAFF AND BUSINESS OWNERS',
    features_text: 'Each of these features is designed to enhance hotel management efficiency, improve guest service quality, and provide flexibility in system configuration according to your business needs.',
    features_item_1_title: 'Hotel Information and Services',
    features_item_1_text: 'The app contains all the necessary information about the hotel, including photos, operating schedules, restaurant menus, price lists, and descriptions of additional services. Guests will have access to this information even after they leave the hotel.',
    features_item_2_title: 'Request Management',
    features_item_2_text: 'Staff receive requests through the app and can see how long a request has been in the system and who created it. Employees can accept, cancel, or complete requests. After completion, the request is automatically saved in the history, where the administrator can always see who handled the request and how long it took. This ensures transparency and control over service quality.',
    features_item_3_title: 'Schedule of Additional Services Reservations',
    features_item_3_text: 'Guests can book additional services, such as massages or sauna sessions, through the app, and the staff receives these requests for processing. Once confirmed, the request appears in the schedule for the specific service. There is also an option to set up bookings through direct communication (chat) with the staff. You can also block all available slots for cleaning or repairs.',
    features_item_4_title: 'Reservation Schedule',
    features_item_4_text: 'The staff app provides a convenient reservation schedule where employees can confirm or cancel bookings and contact customers. You can manually add reservations if guests call directly, which allows you to keep all information in one place.',
    features_item_5_title: 'Guest Reviews and Information Management',
    features_item_5_text: 'All reviews left by guests are stored in the app along with guest contact information. This allows staff to provide feedback to clients or quickly resolve any issues, improving service quality.',
    features_item_6_title: 'Employee Database',
    features_item_6_text: 'The system allows you to create accounts for an unlimited number of employees, each assigned to a specific department. This enables employees to receive only those requests that pertain to their department, increasing work efficiency.',
    features_item_7_title: 'Advertising Hotel and Partner Services',
    features_item_7_text: 'You can add an unlimited number of advertising banners to the app, which will lead to a page with information about the advertised service of your hotel or your partners. This will influence the growth of sales and profits. Partner advertising is paid separately at a loyal price.',
    features_item_8_title: 'Mobile and Desktop Versions',
    features_item_8_text: 'The staff app is available in two versions — mobile and desktop, providing convenience for working in any location and on any device.',
    features_item_9_title: '24/7 Support',
    features_item_9_text: 'Our support service operates 24/7 to help resolve any questions or technical issues. We are always available to ensure the smooth operation of your hotel.',
    features_item_10_title: 'Additional Features',
    features_item_10_text: 'The program includes additional features such as software for printing kitchen order receipts, SMS messaging with promotions and offers, and the printing of QR codes with menus or any other information.',
    qr_title: 'QR CODES FOR EVERY ROOM',
    qr_text: 'Our system allows you to create and print special QR codes for the restaurant menu, which are placed on tables. This provides guests with a convenient way to order food and drinks directly from their smartphones. This feature significantly enhances the dining experience, improving convenience and efficiency for both guests and staff. Implementing QR codes simplifies the service process and reduces the workload on staff, allowing them to focus on service quality and meeting customer needs.',
    qr_item_1_title: 'QR Code Placement:',
    qr_item_1_text: 'Each table in the restaurant is equipped with a unique QR code that leads to a digital menu.',
    qr_item_2_title: 'Scanning and Ordering:',
    qr_item_2_text: 'The hotel guest scans the QR code with their smartphone and gains access to the full restaurant menu. After selecting dishes and drinks, the guest can place an order through the app.',
    qr_item_3_title: 'Order Processing:',
    qr_item_3_text: 'The order automatically goes to the staff\'s workspace, where they see:<br/><br/>- The table number from which the order was placed.<br/> - Details of the ordered dishes and drinks.<br/> - The payment method chosen by the guest (cash, card, or through the app).',
    qr_item_4_title: 'Convenience for Guests:',
    qr_item_4_text: 'Guests can easily browse the menu, choose dishes, and specify their preferences (e.g., allergies or other dietary needs) without waiting for a waiter. This speeds up service and ensures flawless service.',
    qr_item_5_title: 'Benefits for Staff:',
    qr_item_5_text: 'Restaurant staff receive orders electronically, minimizing the risk of errors in information transfer. They can quickly prepare the order and pass it on to the kitchen for execution.',
    qr_item_6_title: 'Payment:',
    qr_item_6_text: 'Guests can choose their preferred payment method, making the order completion process simple and straightforward.',
    qr_hint: '*This feature is provided additionally and is charged separately',
  },

  contacts: {
    footer_link_1: 'CONTACTS',
    footer_link_2: 'PRIVACY POLICY',
    footer_link_3: 'TERMS AND CONDITIONS',
    main_title_header: 'CONTACTS',
    main_title_content: 'Contact us in any convenient way, and we will be happy to answer all your additional questions, conduct an online presentation, and provide all the details of cooperation.',
    contact_icon_telegram: 'TELEGRAM',
    contact_icon_email: 'E-MAIL',
    contact_icon_phone: 'PHONE',
  },

  privacy: {
    header: 'PRIVACY POLICY',
    text_0: 'Smart Hotel Service (hereinafter "Company", "we", "us") respects your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our mobile hotel application (hereinafter "App").',
    title_1: '1. Information We Collect',
    text_1: 'We may collect the following types of information:<br/><br/>- **Personal Data:** During registration or use of the App, we may collect your name, email address, phone number, and other contact details.<br/>- **Usage Data:** We automatically collect information about how you interact with our App, including your IP address, device type, operating system, browser type, and other usage data.<br/>- Financial Data: If you make a payment through our App, we may collect payment information such as credit card number and billing address.',
    title_2: '2. How We Use Your Information',
    text_2: 'We use your information to:<br/><br/>- Provide and improve our App and services.<br/>- Process transactions and manage your orders.<br/>- Provide customer support and respond to your inquiries.<br/>- Provide you with updates, news, and other information about our services if you have agreed to receive such communications.<br/>- Analyze App usage to improve our products and services.',
    title_3: '3. How We Disclose Your Information',
    text_3: 'We do not disclose your personal information to third parties except in the following cases:<br/><br/>- Service Providers: We may share your information with our trusted service providers who help us provide and support the App.<br/>- Legal Requirements: We may disclose your information if required by law or in the context of legal proceedings.',
    title_4: '4. Data Security',
    text_4: 'We implement appropriate technical and organizational measures to protect your information from unauthorized access, loss, or disclosure. However, no security system is entirely impenetrable, and we cannot guarantee absolute security of your data.',
    title_5: '5. Your Rights',
    text_5: 'You have the right to:<br/><br/>- Request access to your personal data.<br/>- Correct or update your information.<br/>- Request deletion of your data if permitted by law.<br/>- Opt-out of receiving marketing communications.',
    title_6: '6. Changes to the Privacy Policy',
    text_6: 'We may update this Privacy Policy from time to time. The latest changes will be published on this page. We encourage you to periodically review this Policy to stay informed of any changes.',
    title_7: '7. Contact Information',
    text_7: 'If you have any questions or comments regarding this Privacy Policy, please contact us at:<br/><br/>**Smart Hotel Service**<br/>[Your address]<br/>[Your address]<br/>[Your email]',
  },

  conditions: {
    header: 'TERMS OF USE',
    title_1: '1. General Provisions',
    text_1: '1.1 Welcome to the Smart Hotel Service website ("We", "Our Company"). These Terms of Use ("Terms") govern your use of our mobile hotel application ("Application"). By using our Application, you agree to comply with these Terms. If you do not agree with any part of these Terms, please refrain from using our Application.',
    title_2: '2. Account Registration',
    text_2: '2.1 To access certain features of our Application, you may need to register an account. You agree to provide accurate and up-to-date information during the registration process and to keep your login details secure. You are responsible for all actions taken under your account.',
    title_3: '3. Usage Restrictions',
    text_3: '3.1 You agree not to use our Application for illegal purposes or in violation of these Terms. You are prohibited from engaging in the following activities:',
    title_4: '- Unauthorized access to or use of our Application.',
    text_4: '- Interfering with or disrupting the normal operation of our Application.',
    title_5: '- Distribution of viruses, malware, or other harmful software.',
    text_5: '- Violation of any applicable laws or regulations.',
    title_6: '4. Intellectual Property',
    text_6: '4.1 All materials available on our Application, including design, text, images, and software, are the property of Smart Hotel Service or its licensors and are protected by intellectual property laws. You may not copy, modify, distribute, or use these materials without prior written permission from Save Time Service.',
    title_7: '5. Liability',
    text_7: '5.1 Smart Hotel Service shall not be liable for any damages arising from the use or inability to use our Application, including, but not limited to, indirect, incidental, or consequential damages, even if Save Time Service has been advised of the possibility of such damages.',
    title_8: '6. Amendments to the Terms',
    text_8: '6.1 We reserve the right to change these Terms at any time without prior notice. The latest version of the Terms will be published on this page. By continuing to use our Application after the changes have been made, you agree to the updated Terms.',
    title_9: '7. Contact Information',
    text_9: 'If you have any questions or comments regarding these Terms, please contact us at:<br/><br/>**Smart Hotel Service**<br/>[Your address]<br/>[Your address]<br/>[Your email]',
  },

  client: {
    main_title: 'NEW LEVEL OF GUESTS COMFORT CONDITIONS',
    main_text: 'Our app makes guests’ stay at the hotel as comfortable as possible, allowing them to order any services directly from their room. All issues are resolved quickly thanks to 24/7 online support, and access to all necessary hotel information is always at hand. This ensures a worry-free stay and a high level of service.',
    steps_title: 'THREE SIMPLE STEPS',
    steps_item_1: 'Create a personalized link to your hotel’s app by filling out a simple form in the staff panel',
    steps_item_2: 'Allow the guest to scan the QR code that appeared on the screen, or send the link to their email',
    steps_item_3: 'Fulfill guest requests, gather positive feedback, and enjoy your work.',
    features_title: 'NEW FEATURES FOR GUESTS',
    features_item_1_title: 'Service Orders: ',
    features_item_1_text: 'Guests can easily order cleaning, extra pillows, taxis, food and drinks, as well as book spa treatments, a sauna, or a massage directly from their room.',
    features_item_2_title: 'Reception Requests:',
    features_item_2_text: 'Any requests or questions can be sent directly through the app, making communication with the hotel easier.',
    features_item_3_title: 'Reviews:',
    features_item_3_text: 'Guests can leave reviews for any request, helping the hotel improve service quality.',
    features_item_4_title: 'Notifications:',
    features_item_4_text: 'Guests receive notifications about the status of their requests, including the start, completion, or cancellation of the request.',
    features_item_5_title: 'Automatic Blocking:',
    features_item_5_text: 'After checkout, access to hotel services such as food ordering or bookings is automatically blocked, but information and room booking options remain available.',
    features_item_6_title: 'Hotel Information:',
    features_item_6_text: 'The app provides access to information about the hotel, restaurant, and leisure options, allowing guests to conveniently learn about all offers.',
    features_item_7_title: 'Additional Features:',
    features_item_7_text: 'For the convenience of guests and the hotel, online payments, SMS notifications about news and discounts are available. If a special feature is needed, we can implement it after discussing the details.',
    features_item_8_title: 'Direct Contact Feature:',
    features_item_8_text: 'The app allows for direct contact with the administrator or reception staff. Guests can send messages directly to staff via the app at any time, ensuring quick resolution of issues and improving service levels.',
    features_item_9_title: 'Request History:',
    features_item_9_text: 'The app keeps a history of all requests, reservations, and bookings, allowing easy review of previous actions.',
    features_item_10_title: 'START NOW',
    features_item_10_text: 'Click here, and we will give you a personalized presentation of the app',
    features_item_11_title: 'Reservations:',
    features_item_11_text: 'The ability to rebook a room after a previous stay makes planning the next vacation simple and quick.',
  },

  drop_menu: {
    header: 'Sections',
    about_us: 'About Us',
    contact: 'Contact',
    client: 'For Clients',
    hotel: 'For Hotels',
    privacy: 'PRIVACY POLICY',
    condition: 'TERMS AND CONDITIONS'
  }
};
