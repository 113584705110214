<template>
  <div class="page" :class="screenType" id="container" ref="container">
    <router-view />
    <scroll-button v-if="isScrollButtonShown" />
  </div>
</template>

<script lang="ts" setup>
//import { event } from 'vue-gtag'

// event('conversion', {
//   send_to: 'AW-16522784734/hAR2CJDrn6QZEN7f1sY9'
// })

import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import ScrollButton from "@/components/Button/ScrollButton.vue";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const route = useRoute();

const screenType = ref('');
const container = ref();
const isScrollButtonShown = ref(false);

const setScreenType = () => screenType.value = document.documentElement.clientWidth > 800 ? 'desktop' : 'mobile';

const setLanguage = () => {
  if (route.query?.lang) {
    i18n.locale.value = route.query.lang as string;
  } else {
    let lang = navigator.language || navigator?.userLanguage;
    if (lang === 'uk') {
      lang = 'ua'
    }
    i18n.locale.value = ['ua', 'en', 'ru', 'pl'].includes(lang) ? lang : 'en';
  }
};

onMounted(() => {
  setLanguage();
  setScreenType();
  window.addEventListener('resize', setScreenType);
  container.value?.addEventListener('scroll', () => {
    isScrollButtonShown.value = container.value?.scrollTop >= 250;
  });
});

onBeforeUnmount(() => window.removeEventListener('resize', setScreenType));

watch(() => route.path, () => {
  document.getElementById('container')?.scrollTo({ top: 0, left: 0 });
  setLanguage();
});
</script>

<style lang="scss">
@import "src/assets/styles/base/core";

.page {
  @extend .flex-col, .no-scroll;
  width: 100dvw;
  height: 100dvh;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.desktop.page {
  padding: calc(min(4vw, 60px));
}

.mobile.page {
  padding: 4.1vw;
}
</style>
