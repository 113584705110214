export const ru = {
  improve_your_business: 'Улучшите свой бизнес вместе с нами',
  free_presentation: 'БЕСПЛАТНАЯ ЖИВАЯ ПРЕЗЕНТАЦИЯ',
  copied: 'Скопировано',
  successful_data_send: 'Ваше письмо успешно отправлено',
  error_data_send: 'Невозможно отправить ваше письмо',

  tabs: {
    about: 'О нас',
    client: 'Для клиента',
    hotel: 'Для отеля',
    contacts: 'Контакты',
  },

  buttons: {
    free_month: 'Бесплатный месяц',
    our_services: 'Наши услуги',
    send: 'Отправить',
    coworking_details: 'Детали сотрудничества'
  },

  home: {
    title_1: 'Smart',
    title_2: 'Hotel',
    title_3: 'Service',
    subtitle: 'Инновационная система, созданная для оптимизации операционной деятельности и повышения прибыльности отеля, при этом обеспечивая гостям исключительный комфорт и удобство во время пребывания.',
    about_title: 'О НАС',
    about_text: 'Представляем Smart Hotel Service, прогрессивное приложение, созданное для упрощения и улучшения отельного опыта для гостей, а также для увеличения прибыли отеля.<br/>Благодаря Smart Hotel Service гости могут заказывать различные отельные услуги, а вы сможете легко вывести свой бизнес на новый уровень.<br/>Внедрение нашей системы позволяет вашему отелю не только повысить уровень обслуживания, но и стать лидером в сфере гостеприимства, обеспечивая исключительный опыт для каждого гостя.',
    form_title: 'ИНДИВИДУАЛЬНАЯ ПРЕЗЕНТАЦИЯ В ОНЛАЙН РЕЖИМЕ ИМЕННО ДЛЯ ВАС',
    form_hint: 'Заполните и отправьте свои данные, и мы перезвоним и договоримся о БЕСПЛАТНОЙ презентации!',
    form_name: 'Имя и Фамилия',
    form_phone: 'Номер Телефона',
    benefits_title: 'ВСЁ В ОДНОЙ СИСТЕМЕ',
    benefits_subtitle: 'В современном мире гостеприимство является одним из ключевых факторов, определяющих успех отеля. Для обеспечения высокого уровня сервиса и удовлетворенности клиентов мы предлагаем инновационную систему управления, которая улучшает все аспекты работы отеля.',
    exp_title: 'ПЕРСОНАЛИЗИРОВАННЫЙ ОПЫТ ГОСТЕЙ',
    exp_text: 'Наша система предоставляет гостям индивидуальное приложение, которое значительно упрощает взаимодействие с отелем. Благодаря приложению гости могут:',
    exp_item_1: 'Заказывать еду прямо в номер, выбирая из разнообразного меню ресторана.',
    exp_item_2: 'Подавать запросы на уборку, выбирая удобное время для обслуживания.',
    exp_item_3: 'Заказывать такси и другие транспортные услуги непосредственно через приложение.',
    exp_item_4: 'Общаться с рецепцией для быстрого решения любых вопросов или запросов.',
    exp_item_5: 'Резервировать спа-процедуры и другие дополнительные услуги отеля.',
    exp_button: 'Все преимущества для гостей',
    tool_title: 'ЭФФЕКТИВНЫЙ ИНСТРУМЕНТ ДЛЯ ПЕРСОНАЛА',
    tool_text: 'Каждый сотрудник отеля имеет доступ к персонализированному рабочему кабинету, который оптимизирует выполнение их задач:',
    tool_item_1: 'Получение запросов от гостей в реальном времени с возможностью немедленного ответа.',
    tool_item_2: 'Мониторинг выполнения запросов с отслеживанием времени и качества предоставления услуг.',
    tool_item_3: 'Графики резервирования и бронирования, что обеспечивает бесперебойный и слаженный процесс обслуживания.',
    tool_item_4: 'Анализ эффективности работы персонала на основе отзывов гостей и времени выполнения запросов.',
    tool_item_5: 'Предоставление обратной связи гостям на основе их отзывов, что помогает формировать доверительные отношения и повышает уровень удовлетворенности клиентов.',
    tool_button: 'Все преимущества для отеля',
    offer_title: 'ПЕРВЫЙ МЕСЯЦ БЕСПЛАТНО',
    offer_text: 'Мы с радостью ответим на все ваши дополнительные вопросы и поддержим вас на каждом этапе внедрения системы, помогая вашему бизнесу выйти на новый уровень успеха.',
    offer_button: 'Хочу улучшить бизнес!',
    reviews_title: 'ОТЗЫВЫ',
    review_1_name: 'Сара, 48 лет',
    review_1_type: 'менеджер отеля',
    review_1_text: 'Сначала я сомневалась в целесообразности внедрения этой системы, но результаты превзошли все ожидания. Программа значительно упростила наши процессы и повысила эффективность работы. Это действительно удачная инвестиция для нашего отеля!',
    review_2_name: 'Джон, 44 года',
    review_2_type: 'владелец отеля',
    review_2_text: 'С внедрением этой системы каждый гость остается довольным нашим сервисом. Мы получаем много похвал за то, что идем в ногу со временем, и заметили значительный рост прибыли. Эта система действительно улучшила наши процессы и повысила общий уровень обслуживания.',
    review_3_name: 'Емили, 32 года',
    review_3_type: 'гость отеля',
    review_3_text: 'Я была приятно удивлена, когда, сканировав код, получила доступ к приложению отеля. Всё было чрезвычайно удобно: я смогла заказать еду, организовать уборку и воспользоваться другими услугами, не выходя из номера. Это действительно классный сервис!',
    review_4_name: 'Джессика, 29 лет',
    review_4_type: 'администратор отеля',
    review_4_text: 'Сначала я была скептически настроена, но она оказалась чрезвычайно полезной. Система значительно облегчила нашу работу, автоматизировав многие рутинные задачи и предоставив удобный доступ к информации. Это настоящий прорыв для нашего отеля!',
    review_5_name: 'Майкл, 52 года',
    review_5_type: 'гость отеля',
    review_5_text: 'Я смог продлить свое пребывание, не выходя из номера, и был приятно удивлен, насколько просто и удобно это сделать через приложение. Хотя я не очень хорошо разбираюсь в новых технологиях, эта система оказалась очень интуитивной и легкой в использовании.',
    points_title: 'ЧТО ВЫ ПОЛУЧИТЕ, ОБНОВИВ СВОЙ БИЗНЕС?',
    points_text: 'Внедрение нашей системы управления отелем приносит многочисленные преимущества, способствующие улучшению качества обслуживания и эффективности вашего бизнеса. Вот основные преимущества нашей системы:',
    points_item_1_title: 'Современные технологии:',
    points_item_1_text: 'Внедрение современных технологий оптимизирует операции и повышает общую эффективность отеля.',
    points_item_2_title: 'Автоматизация процессов:',
    points_item_2_text: 'Снижение нагрузки на персонал благодаря автоматизации административных обязанностей.',
    points_item_3_title: 'Персонализированное обслуживание:',
    points_item_3_text: 'Благодаря индивидуальному приложению гости получают удобный доступ ко всем услугам отеля.',
    points_item_4_title: 'Позитивные отзывы:',
    points_item_4_text: 'Высокий уровень обслуживания приводит к увеличению количества положительных отзывов и повышению лояльности гостей.',
    points_item_5_title: 'Правильный фокус:',
    points_item_5_text: 'Персонал может сосредоточиться на выполнении более важных задач, так как повторяющиеся административные обязанности автоматизированы.',
    points_item_6_title: 'Контроль качества работы отеля:',
    points_item_6_text: 'Администраторы могут отслеживать выполнение запросов и контролировать качество обслуживания.',
    points_item_7_title: 'Реклама и продажи:',
    points_item_7_text: 'Возможность рекламы и продажи собственных услуг и услуг партнеров через приложение позволяет отелю привлекать больше клиентов и увеличивать объемы продаж.',
    points_item_8_title: 'Рост прибыльности:',
    points_item_8_text: 'Наша система увеличивает доходы отеля, привлекая больше клиентов и увеличивая объемы продаж. Это непосредственно способствует росту общей прибыльности отеля.',
    points_item_9_title: 'Поддержка 24/7:',
    points_item_9_text: 'Приложение обеспечивает круглосуточную онлайн-поддержку как для гостей, так и для персонала отеля, что позволяет быстро решать любые вопросы.',
    points_item_10_title: 'Обучение персонала:',
    points_item_10_text: 'Предлагаем возможность обучения вашего персонала, чтобы они могли эффективно использовать все функции системы.',
    points_item_11_title: 'Бесплатный тестовый месяц:',
    points_item_11_text: 'Бесплатный месяц использования системы, чтобы отель мог протестировать и внести необходимые конфигурации для оптимального использования.',
    points_item_12_title: 'Гибкость и разнообразие функций:',
    points_item_12_text: 'Система “конструктор” - вы выбираете именно тот набор функций, который лучше всего соответствует потребностям вашего бизнеса.',
  },

  hotel: {
    main_title: 'НОВЫЙ УРОВЕНЬ БИЗНЕСА',
    main_text: 'Внедрение нашей системы значительно повысит эффективность вашего отеля, автоматизируя рутинные процессы и упрощая управление. Удобство для гостей, скорость обработки запросов и интеграция современных технологий поднимут уровень обслуживания, что положительно скажется на репутации и прибыльности отеля.',
    features_title: 'ЭФФЕКТИВНЫЙ ИНСТРУМЕНТ ДЛЯ ПЕРСОНАЛА И ВЛАДЕЛЬЦЕВ БИЗНЕСА',
    features_text: 'Каждая из этих функций разработана для повышения эффективности управления отелем, улучшения качества обслуживания гостей и обеспечения гибкости в настройке системы в соответствии с потребностями вашего бизнеса.',
    features_item_1_title: 'Информация об Отеле и Услугах',
    features_item_1_text: 'Приложение содержит всю необходимую информацию об отеле, включая фотографии, графики работы, меню ресторана, прайс-листы и описания дополнительных услуг. Гости будут иметь доступ к этой информации даже после выезда из отеля.',
    features_item_2_title: 'Управление Запросами',
    features_item_2_text: 'Персонал получает запросы через приложение и имеет возможность видеть, сколько времени запрос находится в системе, а также кто его создал. Сотрудники могут принимать, отменять или завершать запросы. После выполнения запрос автоматически сохраняется в истории, где администратор всегда видит, кто выполнил запрос и какая была продолжительность его выполнения. Это обеспечивает прозрачность и контроль за качеством обслуживания.',
    features_item_3_title: 'График Резерваций Дополнительных Услуг',
    features_item_3_text: 'Гости могут бронировать дополнительные услуги, такие как массаж или сауна, через приложение, а персонал получает эти запросы для обработки. После подтверждения запрос появляется в графике конкретной услуги. Дополнительно есть возможность настроить бронирование услуг через прямую связь (чат) с сотрудником. Также вы можете блокировать все свободные слоты для уборки или ремонта.',
    features_item_4_title: 'График Бронирований',
    features_item_4_text: 'Приложение для персонала предоставляет удобный график бронирований, в котором сотрудники могут подтверждать или отменять бронирования, а также связываться с клиентами. В графике можно вручную добавить бронирования, если гости звонят напрямую, что позволяет сохранять всю информацию в одном месте.',
    features_item_5_title: 'Управление Отзывами и Информацией о Гостях',
    features_item_5_text: 'Все отзывы, оставленные гостями, хранятся в приложении вместе с контактной информацией гостей. Это позволяет сотрудникам предоставлять обратную связь клиентам или быстро решать любые проблемы, повышая качество обслуживания.',
    features_item_6_title: 'База Сотрудников',
    features_item_6_text: 'Система позволяет создавать аккаунты для неограниченного числа сотрудников, каждому из которых назначается конкретный отдел. Это позволяет сотрудникам получать только те запросы, которые касаются их отдела, повышая эффективность работы.',
    features_item_7_title: 'Реклама Услуг Отеля и Партнеров',
    features_item_7_text: 'Вы можете добавлять неограниченное количество рекламных баннеров в приложение, которые будут вести на страницу с информацией о рекламируемой услуге вашего отеля или ваших партнеров. Это будет способствовать росту продаж и прибыли. Реклама партнеров оплачивается отдельно по лояльной цене.',
    features_item_8_title: 'Мобильная и Компьютерная Версия',
    features_item_8_text: 'Приложение для персонала создано в двух вариантах — мобильном и десктопном, что обеспечивает удобство работы в любом месте и на любом устройстве.',
    features_item_9_title: 'Круглосуточная Поддержка',
    features_item_9_text: 'Наша служба поддержки работает круглосуточно, чтобы помочь решить любые вопросы или технические проблемы. Мы всегда на связи, чтобы обеспечить бесперебойную работу вашего отеля.',
    features_item_10_title: 'Дополнительные Функции',
    features_item_10_text: 'Программа включает такие дополнительные функции, как программное обеспечение для печати чеков-заказов на кухне, рассылка SMS-сообщений с акциями и предложениями, а также печать QR-кодов с меню или любой другой информацией.',
    qr_title: 'QR-КОДЫ ДЛЯ КАЖДОГО ПОМЕЩЕНИЯ',
    qr_text: 'Наша система позволяет создавать и печатать специальные QR-коды для меню ресторана, которые размещаются на столиках. Это обеспечивает гостям удобный способ заказа еды и напитков непосредственно со своих смартфонов. Эта функция значительно улучшает опыт пребывания в ресторане, повышая уровень удобства и эффективности как для гостей, так и для персонала. Внедрение QR-кодов упрощает процесс обслуживания и снижает нагрузку на персонал, позволяя сосредоточиться на качестве обслуживания и удовлетворении потребностей клиентов.',
    qr_item_1_title: 'Размещение QR-кодов:',
    qr_item_1_text: 'Каждый столик в ресторане оснащается уникальным QR-кодом, который ведет к цифровому меню.',
    qr_item_2_title: 'Сканирование и Заказ:',
    qr_item_2_text: 'Гость отеля сканирует QR-код с помощью своего смартфона и получает доступ к полному меню ресторана. После выбора блюд и напитков, гость может сделать заказ через приложение.',
    qr_item_3_title: 'Обработка Заказа:',
    qr_item_3_text: 'Заказ автоматически поступает в рабочий кабинет сотрудника, где он видит:<br/><br/>- Номер столика, с которого был сделан заказ.<br/> - Детали заказанных блюд и напитков.<br/> - Способ оплаты гостя (наличными, картой или через приложение).',
    qr_item_4_title: 'Удобство для Гостей:',
    qr_item_4_text: 'Гости могут легко просматривать меню, выбирать блюда и указывать свои пожелания (например, аллергии или другие диетические потребности) без необходимости ждать официанта. Это повышает скорость обслуживания и обеспечивает безупречный сервис.',
    qr_item_5_title: 'Преимущества для Персонала:',
    qr_item_5_text: 'Сотрудники ресторана получают заказы в электронном виде, что минимизирует риск ошибок при передаче информации. Они могут быстро подготовить заказ и передать его на кухню для выполнения.',
    qr_item_6_title: 'Оплата:',
    qr_item_6_text: 'Гости могут выбрать удобный для них способ оплаты, что делает процесс завершения заказа простым и понятным.',
    qr_hint: '*Эта функция предоставляется дополнительно и оплачивается отдельно',
  },

  contacts: {
    footer_link_1: 'КОНТАКТЫ',
    footer_link_2: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ',
    footer_link_3: 'УСЛОВИЯ ИСПОЛЬЗОВАНИЯ',
    main_title_header: 'КОНТАКТЫ',
    main_title_content: 'Свяжитесь с нами удобным вам способом, и мы с радостью ответим на все дополнительные вопросы, проведем презентацию онлайн и предоставим все детали сотрудничества.',
    contact_icon_telegram: 'TELEGRAM',
    contact_icon_email: 'E-MAIL',
    contact_icon_phone: 'ТЕЛЕФОН',
  },


  privacy: {
    header: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ',
    text_0: 'Smart Hotel Service (далее "Компания", "мы", "нас") уважает вашу конфиденциальность и обязуется защищать ваши личные данные. Эта Политика конфиденциальности объясняет, как мы собираем, используем, раскрываем и защищаем вашу информацию при использовании нашего мобильного приложения для отелей (далее "Приложение").',
    title_1: '1. Информация, которую мы собираем',
    text_1: 'Мы можем собирать следующие типы информации:<br/><br/>- **Личные данные:** При регистрации или использовании Приложения мы можем собирать ваше имя, электронную почту, номер телефона и другие контактные данные.<br/>- **Данные о использовании:** Мы автоматически собираем информацию о том, как вы взаимодействуете с нашим Приложением, включая IP-адрес, тип устройства, операционную систему, тип браузера и другие данные о использовании.<br/>- Финансовые данные: Если вы осуществляете платеж через наше Приложение, мы можем собирать платежную информацию, такую как номер кредитной карты и платежный адрес.',
    title_2: '2. Как мы используем вашу информацию',
    text_2: 'Мы используем вашу информацию для:<br/><br/>- Предоставления и улучшения нашего Приложения и услуг.<br/>- Обработки транзакций и управления вашими заказами.<br/>- Предоставления поддержки клиентам и ответов на ваши запросы.<br/>- Предоставления вам обновлений, новостей и другой информации о наших услугах, если вы согласились получать такие коммуникации.<br/>- Анализа использования Приложения для улучшения наших продуктов и услуг.',
    title_3: '3. Как мы раскрываем вашу информацию',
    text_3: 'Мы не раскрываем вашу личную информацию третьим лицам, за исключением случаев:<br/><br/>- Поставщики услуг: Мы можем передавать вашу информацию нашим доверенным поставщикам услуг, которые помогают нам в предоставлении и поддержке Приложения.<br/>- Правовые требования: Мы можем раскрывать вашу информацию в случае требований законодательства или в рамках правовых процедур.',
    title_4: '4. Безопасность данных',
    text_4: 'Мы применяем соответствующие технические и организационные меры для защиты вашей информации от несанкционированного доступа, утраты или раскрытия. Тем не менее, ни одна система безопасности не является абсолютно непроницаемой, и мы не можем гарантировать абсолютную безопасность ваших данных.',
    title_5: '5. Ваши права',
    text_5: 'Вы имеете право:<br/><br/>- Запрашивать доступ к вашим личным данным.<br/>- Исправлять или обновлять вашу информацию.<br/>- Запрашивать удаление ваших данных, если это разрешено законом.<br/>- Отказаться от получения маркетинговых сообщений.',
    title_6: '6. Изменения в политике конфиденциальности',
    text_6: 'Мы можем время от времени обновлять эту Политику конфиденциальности. Последние изменения будут опубликованы на этой странице. Рекомендуем вам периодически проверять эту Политику для ознакомления с любыми изменениями.',
    title_7: '7. Контактная информация',
    text_7: 'Если у вас есть вопросы или замечания по поводу этой Политики конфиденциальности, пожалуйста, свяжитесь с нами по адресу:<br/><br/>**Smart Hotel Service**<br/>[Ваш адрес]<br/>[Ваш телефон]<br/>[Ваш электронный адрес]',
  },

  conditions: {
    header: 'УСЛОВИЯ ИСПОЛЬЗОВАНИЯ',
    title_1: '1. Общие положения',
    text_1: '1.1 Добро пожаловать на сайт Smart Hotel Service ("Мы", "Наша компания"). Эти Условия использования ("Условия") регулируют использование вами нашего мобильного приложения для отелей ("Приложение"). Используя наше Приложение, вы соглашаетесь соблюдать эти Условия. Если вы не согласны с этими Условиями, пожалуйста, не используйте наше Приложение.<br/><br/>1.2 Мы оставляем за собой право в любое время изменять эти Условия. Все изменения вступают в силу с момента их публикации в Приложении. Вы обязаны периодически проверять эти Условия на предмет изменений.',
    title_2: '2. Использование Приложения',
    text_2: '2.1 Наше Приложение предназначено для использования в качестве инструмента для управления отелями и бронирования номеров.<br/><br/>2.2 Вы обязаны использовать Приложение только в законных целях и в соответствии со всеми применимыми законами и нормативными актами.<br/><br/>2.3 Вы не имеете права использовать наше Приложение для любых незаконных или несанкционированных целей, включая, но не ограничиваясь, попытками взломать наше Приложение или получить несанкционированный доступ к нему.',
    title_3: '3. Регистрация и аккаунт',
    text_3: '3.1 Для использования некоторых функций нашего Приложения вам может потребоваться создать аккаунт. Вы соглашаетесь предоставить точную и актуальную информацию при регистрации.<br/><br/>3.2 Вы несете ответственность за поддержание конфиденциальности ваших данных для входа в аккаунт и за все действия, совершаемые с вашего аккаунта.<br/><br/>3.3 Вы обязаны немедленно уведомить нас о любом несанкционированном использовании вашего аккаунта или любых других нарушениях безопасности.',
    title_4: '4. Интеллектуальная собственность',
    text_4: '4.1 Весь контент, доступный через наше Приложение, включая текст, графику, логотипы, иконки, изображения и программное обеспечение, является собственностью Smart Hotel Service или её лицензиаров и охраняется законами об авторском праве и другими законами об интеллектуальной собственности.<br/><br/>4.2 Вы не имеете права копировать, изменять, распространять, воспроизводить или продавать любой контент без нашего предварительного письменного разрешения.',
    title_5: '5. Конфиденциальность',
    text_5: '5.1 Ваше использование Приложения подлежит нашей Политике конфиденциальности, которая определяет, как мы собираем, используем и защищаем вашу личную информацию.<br/><br/>5.2 Используя наше Приложение, вы соглашаетесь с условиями нашей Политики конфиденциальности.',
    title_6: '6. Ответственность',
    text_6: '6.1 Мы не несем ответственности за любые прямые, косвенные, случайные, особые или последующие убытки, возникающие в результате использования или невозможности использования нашего Приложения.<br/><br/>6.2 Мы не гарантируем бесперебойную или безошибочную работу Приложения и не несем ответственности за любые ошибки или неточности.',
    title_7: '7. Изменения и прекращение',
    text_7: '7.1 Мы оставляем за собой право в любое время изменять или прекращать функционирование Приложения, уведомив об этом заранее.<br/><br/>7.2 Вы обязаны прекратить использование Приложения, если вы не согласны с любыми изменениями в этих Условиях.',
    title_8: '8. Применимое право',
    text_8: '8.1 Эти Условия подлежат законам Украины. Все споры, возникающие в связи с этими Условиями, решаются в судах Украины.',
    title_9: '9. Контактная информация',
    text_9: '9.1 Если у вас есть вопросы или комментарии по поводу этих Условий, пожалуйста, свяжитесь с нами по адресу:<br/><br/>**Smart Hotel Service**<br/>[Ваш адрес]<br/>[Ваш телефон]<br/>[Ваш электронный адрес]',
  },

  client: {
    main_title: 'НОВЫЙ УРОВЕНЬ КОМФОРТА ДЛЯ ГОСТЕЙ',
    main_text: 'Наше приложение делает пребывание гостей в отеле максимально комфортным, позволяя им заказывать любые услуги прямо из номера. Все вопросы решаются быстро благодаря круглосуточной онлайн-поддержке, а доступ к всей необходимой информации о гостинице всегда под рукой. Это обеспечивает беспечный отдых и высокий уровень сервиса.',
    steps_title: 'ТРИ ПРОСТЫХ ШАГА',
    steps_item_1: 'Создайте персонализированную ссылку на приложение вашего отеля, заполнив простую форму в панели для сотрудников',
    steps_item_2: 'Позвольте гостю отсканировать QR-код, который появился на экране, или отправьте ссылку на электронную почту',
    steps_item_3: 'Выполняйте запросы гостей, собирайте положительные отзывы и наслаждайтесь работой.',
    features_title: 'НОВЫЕ ФУНКЦИИ ДЛЯ ГОСТЕЙ',
    features_item_1_title: 'Заказ услуг: ',
    features_item_1_text: 'Гости могут легко заказать уборку, дополнительные подушки, такси, еду и напитки, а также забронировать спа-процедуры, сауну или массаж прямо из номера.',
    features_item_2_title: 'Запросы на ресепшн:',
    features_item_2_text: 'Любые запросы или вопросы можно отправить напрямую через приложение, что упрощает коммуникацию с отелем.',
    features_item_3_title: 'Отзывы:',
    features_item_3_text: 'Гости могут оставить отзывы на любой запрос, что помогает отелю улучшать качество обслуживания.',
    features_item_4_title: 'Уведомления:',
    features_item_4_text: 'Гости получают уведомления о статусе своих запросов, включая начало, завершение или отмену выполнения.',
    features_item_5_title: 'Автоматическая блокировка:',
    features_item_5_text: 'После выезда доступ к услугам отеля, таким как заказ еды или бронирование, автоматически блокируется, но информация и возможность бронирования номеров остаются доступными.',
    features_item_6_title: 'Информация об отеле:',
    features_item_6_text: 'Приложение предоставляет доступ к информации об отеле, ресторане и досуге, что позволяет гостям удобно ознакомиться со всеми предложениями.',
    features_item_7_title: 'Дополнительные функции:',
    features_item_7_text: 'Для удобства гостей и отеля доступны онлайн-оплата, SMS-уведомления о новостях и скидках. Если нужна специальная функция, мы можем реализовать её после обсуждения деталей.',
    features_item_8_title: 'Функция прямого контакта:',
    features_item_8_text: 'Приложение позволяет напрямую связаться с администратором или сотрудником ресепшн. Гости могут в любое время отправлять сообщения напрямую сотруднику через приложение, что обеспечивает быстрое решение вопросов и повышает уровень обслуживания.',
    features_item_9_title: 'История запросов:',
    features_item_9_text: 'В приложении хранится история всех запросов, бронирований и резервирований, что позволяет легко просматривать предыдущие действия.',
    features_item_10_title: 'НАЧНИТЕ УЖЕ СЕЙЧАС',
    features_item_10_text: 'Нажмите здесь, и мы проведем вам индивидуальную презентацию приложения',
    features_item_11_title: 'Бронирования:',
    features_item_11_text: 'Возможность повторного бронирования номера после предыдущего пребывания делает планирование следующего отдыха простым и быстрым.',
  },

  drop_menu: {
    header: 'Разделы',
    about_us: 'О нас',
    contact: 'Контакты',
    client: 'Для клиентов',
    hotel: 'Для отелей',
    privacy: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ',
    condition: 'УСЛОВИЯ И ПОЛОЖЕНИЯ'
  }
};
